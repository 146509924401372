<template>
  <Menu>
    <MenuButton as="button" class="text-slate-500 w-8 h-8 flex items-center justify-center hover:text-lime-600 dark:text-slate-400 dark:hover:text-lime-600 focus:outline-none">
      <span class="sr-only">Navigation</span>
      <svg width="24" height="24" fill="none" aria-hidden="true">
        <path d="M12 6v.01M12 12v.01M12 18v.01M12 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
      </svg>
    </MenuButton>

    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >

      <MenuItems class="absolute right-1 top-1 mt-3 w-56 origin-top-right divide-y divide-gray-100 dark:divide-slate-50/[0.06] rounded-md bg-white dark:bg-slate-900 shadow-lg ring-1 ring-black dark:ring-slate-50/[0.06] ring-opacity-5 focus:outline-none">
        <MenuItem v-for="link in Links" :key="link.name" as="template" v-slot="{ active }">
          <a :href="link.link" :class="[
              active ? 'bg-lime-600 dark:hover:bg-lime-600 text-white' : '',
              'group flex w-full items-center rounded-md px-2 py-2 text-sm font-semibold'
            ]">
            <span>{{ link.name }}</span>
          </a>
        </MenuItem>
        <MenuItem>
          <ul class="flex space-x-8 py-2">
            <li v-for="social in Socials" :key="social.description" as="template">
              <a :href="social.link" class="ml-6 block text-slate-400 hover:text-lime-500 dark:hover:text-lime-600" title="{{ social.description }}">
                <span class="sr-only">{{ social.description }}</span>
                <svg viewBox="0 0 16 16" class="w-5 h-5" fill="currentColor" aria-hidden="true" v-html="social.paths"></svg>
              </a>
            </li>
          </ul>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
</script>

<script>
import links from '../_data/links.json'
import socials from '../_data/socials.json'

export default {
  name: 'LinksApp',
  data() {
    return {
        Links: links,
        Socials: socials
    };
  },
}
</script>

